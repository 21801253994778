/** @format */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MillService from "services/MillService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  data: [],
  millDetails: null,
  searchText: "",
  filterTechs: [],
  filterUsages: [],
  sortBy: "",
};

export const create = createAsyncThunk(
  "mill/create",
  async (data: any, { rejectWithValue }) => {
    try {
      console.log("data", data);
      const response = await MillService.create(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const updateMill = createAsyncThunk(
  "mill/updateMill",
  async (data: any, { rejectWithValue }) => {
    try {
      console.log("data", data);
      const response = await MillService.updateMill(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAll = createAsyncThunk(
  "mill/getAll",
  async (search, { rejectWithValue }) => {
    try {
      const response = await MillService.getAll(search);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getDetails = createAsyncThunk(
  "mill/getDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await MillService.getDetails(id);
      console.log("response", response);
      return response;
    } catch (err) {
      console.log("error", err);
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const deleteMill = createAsyncThunk(
  "mill/deleteMill",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await MillService.deleteMill(id);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const millSlice = createSlice({
  name: "mill",
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setFilterTechs: (state, action) => {
      state.filterTechs = action.payload;
    },
    setFilterUsages: (state, action) => {
      state.filterUsages = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.loading = true;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
      })
      .addCase(create.rejected, (state, action) => {
        // state.message = action.payload;
        // state.showMessage = true;
        state.loading = false;
      })
      .addCase(updateMill.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMill.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
      })
      .addCase(updateMill.rejected, (state, action) => {
        // state.message = action.payload;
        // state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.data = [];
      })
      .addCase(getDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.millDetails = action.payload;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.millDetails = null;
      })
      .addCase(deleteMill.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMill.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMill.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { setSearchText, setFilterTechs, setFilterUsages, setSortBy } =
  millSlice.actions;

export default millSlice.reducer;
