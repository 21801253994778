/** @format */

import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.mill",
    path: `${APP_PREFIX_PATH}/dashboards/mill`,
    component: React.lazy(() => import("views/app-views/dashboards/mill")),
  },
  {
    key: "dashboard.millDetails",
    path: `${APP_PREFIX_PATH}/dashboards/millDetails/:id`,
    component: React.lazy(
      () => import("views/app-views/dashboards/millDetails")
    ),
  },
  {
    key: "dashboard.add-mill",
    path: `${APP_PREFIX_PATH}/dashboards/add-mill`,
    component: React.lazy(() => import("views/app-views/dashboards/addMill")),
  },
  {
    key: "dashboard.edit-mill",
    path: `${APP_PREFIX_PATH}/dashboards/edit-mill/:id`,
    component: React.lazy(() => import("views/app-views/dashboards/editMill")),
  },
  // {
  //   key: "dashboard.add-customer",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-customer`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/addCustomer")
  //   ),
  // },
  // {
  //   key: "dashboard.broker",
  //   path: `${APP_PREFIX_PATH}/dashboards/broker`,
  //   component: React.lazy(() => import("views/app-views/dashboards/broker")),
  // },
  // {
  //   key: "dashboard.broker-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/broker-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/brokerDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.owner-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/owner-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/ownerDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.vehicle-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/vehicle-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/vehicleDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.load-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/load-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/loadDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.edit-owner",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-owner/:id`,
  //   component: React.lazy(() => import("views/app-views/dashboards/editUser")),
  // },
  // {
  //   key: "dashboard.add-owner",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-owner`,
  //   component: React.lazy(() => import("views/app-views/dashboards/addUser")),
  // },
  // {
  //   key: "dashboard.edit-vehicle",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-vehicle/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/editVehicle")
  //   ),
  // },
  // {
  //   key: "dashboard.add-vehicle",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-vehicle`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/addVehicle")
  //   ),
  // },
  // {
  //   key: "dashboard.edit-broker",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-broker/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/editBroker")
  //   ),
  // },
  // {
  //   key: "dashboard.add-broker",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-broker`,
  //   component: React.lazy(() => import("views/app-views/dashboards/addBroker")),
  // },
  // {
  //   key: "dashboard.edit-load",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-load/:id`,
  //   component: React.lazy(() => import("views/app-views/dashboards/editLoad")),
  // },
  // {
  //   key: "dashboard.add-load",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-load`,
  //   component: React.lazy(() => import("views/app-views/dashboards/addLoad")),
  // },
  // {
  //   key: "dashboard.customer",
  //   path: `${APP_PREFIX_PATH}/dashboards/customer`,
  //   component: React.lazy(() => import("views/app-views/dashboards/customer")),
  // },
  // {
  //   key: "dashboard.customer-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/customer-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/customerDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.edit-customer",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-customer/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/editCustomer")
  //   ),
  // },
  // {
  //   key: "dashboard.add-customer",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-customer`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/addCustomer")
  //   ),
  // },
  // {
  //   key: "dashboard.trip",
  //   path: `${APP_PREFIX_PATH}/dashboards/trip`,
  //   component: React.lazy(() => import("views/app-views/dashboards/trip")),
  // },
  // {
  //   key: "dashboard.trip-details",
  //   path: `${APP_PREFIX_PATH}/dashboards/trip-details/:id`,
  //   component: React.lazy(
  //     () => import("views/app-views/dashboards/tripDetails")
  //   ),
  // },
  // {
  //   key: "dashboard.edit-trip",
  //   path: `${APP_PREFIX_PATH}/dashboards/edit-trip/:id`,
  //   component: React.lazy(() => import("views/app-views/dashboards/editTrip")),
  // },
  // {
  //   key: "dashboard.add-trip",
  //   path: `${APP_PREFIX_PATH}/dashboards/add-trip`,
  //   component: React.lazy(() => import("views/app-views/dashboards/addTrip")),
  // },
];
