/** @format */

import React, { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import Loading from "components/shared-components/Loading";
import Routes from "routes";
import { RootState } from "store";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const blankLayout = useSelector(
    (state: RootState) => state.theme.blankLayout
  );

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const direction = useSelector((state: RootState) => state.theme.direction);

  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}>
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
