/** @format */

import fetch from "auth/FetchInterceptor";

interface MillData {
  // Define the structure of your mill data here
}

const MillService = {
  create: (data: MillData): Promise<any> => {
    return fetch({
      url: "mills",
      method: "post",
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateMill: (data: MillData): Promise<any> => {
    return fetch({
      url: `mills/${data.id}`,
      method: "put",
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getAll: (search): Promise<any> => {
    return fetch({
      url: `mills/getAll?${search}`,
      method: "get",
    });
  },

  getDetails: (id: string): Promise<any> => {
    return fetch({
      url: `mills/${id}`,
      method: "get",
    });
  },

  deleteMill: (id: string): Promise<any> => {
    return fetch({
      url: `mills/${id}`,
      method: "delete",
    });
  },
};

export default MillService;
