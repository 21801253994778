/** @format */

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import history from "./history";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "configs/AppConfig";
import Layouts from "layouts";

import { createBrowserHistory, History } from "history";
import { store } from "store";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const customHistory: History = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point">
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
