/** @format */

import { configureStore } from "@reduxjs/toolkit";
import { authSlice, millSlice, themeSlice } from "./slices";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    theme: themeSlice,
    mill: millSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
